// const api_url = 'https://m-zbr0.onrender.com/api';
var IS_LIVE = 1
var api_url = ""
var domain_url = ""

if (IS_LIVE === 1) {
    api_url = 'https://app.maxiumsys.com/api';
    domain_url = 'https://app.maxiumsys.com/';

} else {
    api_url = 'http://127.0.0.1:8000/api';
    domain_url = 'http://127.0.0.1:8000/';
}

export { api_url, domain_url }